import * as Constants from '../constants'

export function dataSchema(data) {
  return {
    type: 'object',
    properties: {
      email: {
        type: 'string',
        pattern: Constants.EmailPattern,
      },
      password: {
        type: 'string',
        pattern: Constants.PasswordPattern,
      },
      phonenumber: {
        type: 'string',
        pattern: /^[0-9]+$/,
        minLength: 4,
        maxLength: 15,
      },
      countryCallingCode: {
        type: 'string',
        enum: data.countryCallingCode,
      },
      firstName: {
        type: 'string',
        pattern: Constants.katakanaAndLatinPattern,
      },
      lastName: {
        type: 'string',
        pattern: Constants.katakanaAndLatinPattern,
      },
      street: {
        type: 'string',
      },
      building: {
        type: 'string',
      },
      zip: {
        type: 'string',
      },
      city: {
        type: 'string',
      },
      country: {
        type: 'string',
        enum: data.country,
      },
      bdayDay: {
        type: 'string',
        maximum: 31,
        minimum: 1,
      },
      bdayMonth: {
        type: 'string',
        maximum: 12,
        minimum: 1,
      },
      bdayYear: {
        type: 'string',
        maximum: data.maxYear,
        minimum: 1900,
      },
      terms: {
        const: true,
        type: 'boolean',
      },
      privacy: {
        const: true,
        type: 'boolean',
      },
      promotional: {
        type: 'boolean',
      },
    },
    allOf: [
      {
        if: {
          properties: { country: { const: 'Canada' } },
        },
        then: {
          properties: {
            zip: {
              pattern: Constants.CanadaZipCodePattern,
            },
          },
        },
        else: {
          properties: {
            zip: {
              pattern: Constants.ZipCodePattern,
            },
          },
        },
      },
      // if it's April, June, September or November
      {
        if: {
          properties: {
            bdayMonth: { pattern: '^(04|4|06|6|09|9|11)' },
          },
        },
        then: {
          properties: {
            bdayDay: {
              maximum: 30,
            },
          },
        },
        else: {
          properties: {
            bdayDay: {
              maximum: 31,
            },
          },
        },
      },
      // If it's February
      {
        if: {
          properties: {
            bdayMonth: { pattern: '^(02|2)' },
          },
          required: ['bdayMonth'],
        },
        then: {
          properties: {
            bdayDay: {
              maximum: 29,
            },
          },
        },
      },
      // If it's February and not a leap year
      {
        if: {
          properties: {
            bdayMonth: { pattern: '^(02|2)' },
          },
          not: {
            properties: {
              bdayYear: {
                pattern: '^((((19|20)(04|08|[2468][048]|[13579][26]))|2000))',
              },
            },
          },
          required: ['bdayMonth', 'bdayYear'],
        },
        then: {
          properties: {
            bdayDay: {
              maximum: 28,
            },
          },
        },
      },
    ],
    required: [
      'bdayDay',
      'bdayMonth',
      'bdayYear',
      'city',
      data.country?.length > 1 && 'country',
      'countryCallingCode',
      'email',
      'firstName',
      'lastName',
      'password',
      'phonenumber',
      'privacy',
      'street',
      'terms',
      'zip',
    ].filter(Boolean),
  }
}
