import * as React from 'react'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'

import * as Constants from './constants'
import { Button } from './button'
import { HtmlContent } from './html-content'

export function SeoSection() {
  const translate = Herz.I18n.useTranslate()
  const [isExtended, setIsExtended] = React.useState(false)

  function handleExtended() {
    setIsExtended(prevState => !prevState)
  }

  return (
    <Common.Box
      maxWidth={Constants.ContentMaxWidth}
      display="flex"
      flexDirection="column"
      px="16px"
    >
      <Common.Box
        position="relative"
        overflow={isExtended ? null : 'hidden'}
        maxHeight={isExtended ? '100%' : '230px'}
        color="#ACACAC"
        fontSize="13px"
        fontWeight="300"
        lineHeight="18px"
      >
        <HtmlContent
          html={{
            __html: translate('landing-page.seo-content'),
          }}
        />
        <Common.Box
          bottom="0px"
          top="30%"
          right="0px"
          left="0px"
          background={
            isExtended
              ? null
              : 'linear-gradient(180deg, rgba(19, 8, 48, 0.20) 0%, #130830 81.25%)'
          }
          position="absolute"
        />
      </Common.Box>
      <Common.Box width="100%" display="flex" justifyContent="center">
        <Button
          style={{ maxWidth: '240px' }}
          variant="secondary"
          onClick={handleExtended}
        >
          {isExtended
            ? translate('icons.show-less')
            : translate('icons.show-more')}
        </Button>
      </Common.Box>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  ['icons.show-more', 'icons.show-less', 'landing-page.seo-content'],
  SeoSection
)
