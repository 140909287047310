import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'

export function SellingPoint(props) {
  return (
    <Common.Box
      maxWidth="275px"
      minWidth="150px"
      width="100%"
      height="100%"
      py={20}
      px={16}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Common.Box height="122px" mb="2">
        <img height="100%" src={props.img} alt="selling-point"></img>
      </Common.Box>
      <Common.Box
        as="h3"
        style={{ textTransform: 'uppercase' }}
        fontWeight="700"
        color="lavender"
        fontSize="24px"
        textAlign="center"
        py={0}
      >
        {props.title}
      </Common.Box>
      <Common.Box
        as="p"
        color="lavender"
        textAlign="center"
        fontSize="13px"
        alignSelf="flex-end"
        letterSpacing="0.02em"
        fontWeight="300"
        lineHeight="18px"
        opacity="0.8"
      >
        {props.description}
      </Common.Box>
    </Common.Box>
  )
}

SellingPoint.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
}
