import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'

export function ProgressBar(props) {
  return (
    <Common.Box display="flex" pb={1}>
      {R.times(active => {
        return (
          <Common.Box
            key={active}
            display="flex"
            flexDirection="column"
            flexGrow="1"
            style={{ transition: 'opacity 0.2s ease-in' }}
          >
            <Common.Box
              flexGrow="1"
              height="8px"
              backgroundColor={active <= props.step ? 'lavender' : '#fff'}
              borderRadius="4px"
              mx="2px"
              textAlign="center"
              fontSize={0}
              fontWeight="bold"
              padding="4px"
            ></Common.Box>
          </Common.Box>
        )
      }, props.steps)}
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(['registration.progress.step'], ProgressBar)

ProgressBar.propTypes = {
  steps: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
}

// for @loadable/components
export default ProgressBar
