import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'
import { lazy as loadable } from '@loadable/component'

import { useServerConfiguration } from './server-configuration'

const LoginForm = loadable(() => import('./login-form'))

export function LoginBox(props) {
  const translate = Herz.I18n.useTranslate()

  const { country, countries } = useServerConfiguration()
  if (!country.enabled) {
    return (
      <React.Fragment>
        <LoginForm labelFontColor={props.labelFontColor} />
        <Common.Box display="flex" flexDirection="column" alignItems="center">
          <Common.Box px={2} pb={2} pt={7} display="flex" flexDirection="row">
            <Common.Box fontSize={7}>🌍</Common.Box>
            <Common.Box color="white" fontSize={3} pl={1}>
              {translate('unsupported-market.information')}
            </Common.Box>
          </Common.Box>

          {countries.length > 0 && (
            <Common.Box
              as={ReactRouter.Link}
              fontSize={2}
              to="/change-location"
            >
              <u>{translate('unsupported-market.change')}</u>
            </Common.Box>
          )}
        </Common.Box>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <LoginForm labelFontColor={props.labelFontColor} />
      <Common.Box color="darken-gray" pt={2} textAlign="center" fontSize="12px">
        {translate('login-page.create-account.content')}
        <Common.Space pt={0}>
          <ReactRouter.Link
            color="header-text"
            to="?register=me"
            data-testid="login-page-register"
          >
            <Common.Text color="page-nav-blue" textDecoration="underline">
              {translate('login-page.create-account.cta')}
            </Common.Text>
          </ReactRouter.Link>
        </Common.Space>
      </Common.Box>
    </React.Fragment>
  )
}

Herz.I18n.Loader.preload(
  [
    'unsupported-market.information',
    'unsupported-market.change',
    'login-page.create-account.content',
    'login-page.create-account.cta',
    'forgot-password',
    'login.submit.label',
  ],
  LoginBox
)

LoginBox.propTypes = {
  labelFontColor: PropTypes.string,
}

// For @loadable/components
export default LoginBox
