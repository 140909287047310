import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Constants from '../constants'
import * as Cookies from '../cookies-module'
import { Button } from '../button'
import { LoginBox } from '../login-box'
import { Tick } from '../icons'
import { useMenuQueries } from '../use-menu-queries'

const HeaderText = styled.h3`
  font-feature-settings: 'clig' off, 'liga' off;
  leading-trim: both;
  text-edge: cap;
  background: linear-gradient(143deg, #ffcd01 16.28%, #dc6a09 92.66%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const Logotype = styled('div')`
  ${css({
    width: '60px',
    height: '60px',
    backgroundImage: 'url(/images/logo-icon.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    margin: 'auto',
  })};
`

const BlurredContainer = styled('div')`
  ${css({
    backdropFilter: 'blur(16px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    background: 'rgba(38, 18, 94, 0.6)',
    borderRadius: '16px',
    padding: '24px',
    gap: '16px',
  })};
`

export function LandingBanner() {
  const translate = Herz.I18n.useTranslate()
  const [returningPlayer] = Cookies.useCookie('returning_player')

  const { registerQuery } = useMenuQueries()

  if (returningPlayer) {
    return (
      <Common.Box
        backgroundImage={`radial-gradient(69.88% 95.44% at 50% 50%, rgba(38, 12, 77, 0.00) 0%, #130830 100%), url(${translate(
          'landing-page.hero.background'
        )})`}
        border={['none', '1px solid rgba(255, 255, 255, 0.10)']}
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        backgroundSize="cover"
        py={[null, 1, 2]}
        maxWidth={['100%', null, Constants.ContentMaxWidth]}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        color="white"
        borderRadius={[null, '30px']}
        position="relative"
      >
        <Common.Box
          display="flex"
          flexDirection="column"
          width="100%"
          maxWidth={['90%', '345px']}
          backgroundColor="blueberry"
          borderRadius="16px"
          px="20px"
          py={[3, '20px']}
          my={['40px', '0px']}
        >
          <Common.Box display="flex" flexDirection="column" textAlign="center">
            <Logotype />
            <Common.Box
              fontSize="22px"
              fontWeight="700"
              lineHeight="32px"
              mb="0px"
              mt="8px"
            >
              {translate('login-banner.welcome')}
              <Common.Box
                as="span"
                fontSize="22px"
                fontWeight="700"
                lineHeight="32px"
                ml="5px"
              >
                {translate('login-banner.back')}
              </Common.Box>
            </Common.Box>
          </Common.Box>
          <>
            <Common.Box
              width="100%"
              height="120px"
              mb="16px"
              backgroundImage={`url(${translate('landing-banner.image')})`}
              backgroundSize="cover"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              borderRadius="10px"
            />
            <LoginBox />
          </>
        </Common.Box>
      </Common.Box>
    )
  }

  return (
    <Common.Box
      backgroundImage={`linear-gradient(90deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 100%), url(${translate(
        'landing-page.hero.background'
      )})`}
      border={['none', '1px solid rgba(255, 255, 255, 0.10)']}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="cover"
      py={[null, 1, 2]}
      maxWidth={['100%', null, Constants.ContentMaxWidth]}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      color="white"
      borderRadius={[null, '30px']}
      position="relative"
    >
      <Common.Box
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={['90%', '345px']}
        borderRadius="16px"
        px="20px"
        my={['16px', '0px']}
        marginBottom={['35px', '0px']}
      >
        <Common.Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          textAlign="center"
          justifyContent="center"
          margin="0 auto 6px"
          padding="6px 8px"
          borderRadius="6px"
          background="linear-gradient(108.44deg, #8335FF 3.14%, #18D1B1 100.24%)"
        >
          <Common.Text
            textTransform="uppercase"
            fontSize="8px"
            fontWeight="700"
            lineHeight="10px"
            fontFamily="Inter"
          >
            {translate('login-banner.exclusive-bonus')}
          </Common.Text>
        </Common.Box>

        <Common.Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Common.Box mx="auto" mb={0} textAlign="center">
            <HeaderText style={{ fontSize: '60px', fontWeight: '800' }}>
              {translate('landing-page.money-bonus.up-to')}
            </HeaderText>
            <HeaderText style={{ fontSize: '29px', fontWeight: '800' }}>
              {translate('landing-page.free-spins.game')}
            </HeaderText>
          </Common.Box>
        </Common.Box>

        <Common.Box
          fontSize="29px"
          fontWeight="800"
          textAlign="center"
          textTransform="uppercase"
          maxWidth="240px"
          margin="0 auto 40px"
        >
          {translate('landing-page.deposit-match')}
        </Common.Box>
        <BlurredContainer>
          <Button
            as={ReactRouter.Link}
            to={`?${registerQuery}`}
            style={{ display: 'block', textAlign: 'center' }}
            data-testid="landingpage.register-button"
            stretch
            variant="primary"
            fontSize={3}
          >
            <Common.Box py="2px">
              {translate('landing-page.play-now')}
            </Common.Box>
          </Button>
          <Common.Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            gridGap="8px"
          >
            <Common.Text fontSize="9px">
              <Tick />
            </Common.Text>
            <Common.Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              lineHeight="17px"
              textAlign="center"
            >
              {translate('landing-page.info.fastest-instant')}
            </Common.Text>
          </Common.Box>
          <Common.Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            gridGap="8px"
          >
            <Common.Text fontSize="9px">
              <Tick />
            </Common.Text>
            <Common.Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              lineHeight="17px"
              textAlign="center"
            >
              {translate('landing-page.info.instant-banking')}
            </Common.Text>
          </Common.Box>
          <Common.Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
          >
            <Common.Box
              as="img"
              alt="Trustpilot rating"
              src={translate('landing-page.info.trustpilot-image')}
              mx="11px"
            />
            <Common.Text
              fontFamily="Inter"
              fontSize="12px"
              fontWeight="400"
              lineHeight="14px"
              textAlign="center"
            >
              {translate('landing-page.info.trustpilot-rating')}
            </Common.Text>
          </Common.Box>
        </BlurredContainer>
      </Common.Box>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    'landing-banner.image',
    'landing-page.free-spins.game',
    'landing-page.free',
    'landing-page.spins',
    'landing-page.get-started',
    'landing-page.hero.background',
    'landing-page.hero.flag',
    'landing-page.money-bonus.up-to',
    'landing-page.subheader',
    'landing-page.title',
    'login-banner.back',
    'login-banner.offer',
    'login-banner.welcome',
    'main-menu.register',
    'login-banner.exclusive-bonus',
    'landing-page.deposit-match',
    'landing-page.play-now',
    'landing-page.info.fastest-instant',
    'landing-page.info.instant-banking',
    'landing-page.info.trustpilot-rating',
    'landing-page.info.trustpilot-image',
  ],
  LandingBanner
)
