import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'
import querystring from 'querystring'
import { AnimatePresence, motion } from 'framer-motion'

import * as t from '@rushplay/theme'
import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Icons from './icons'
import * as Suspense from './suspense'
import { ScrollLock } from './scroll-lock'
import { useSafeUpdateQuery } from './use-safe-update-query'

const Overlay = styled(motion.div)`
  ${css({
    zIndex: 'medium',
  })};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
`

const Wrapper = styled(motion.div)`
  ${css({
    width: ['100%', '350px'],
    backgroundColor: 'nav',
    zIndex: 'medium',
  })};
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  line-height: 1.25;
`

const BackWrapper = styled.div`
  ${css({
    fontSize: 6,
  })};

  cursor: pointer;
`

const ScrollableWrapper = styled.div`
  ${css({
    px: 1,
  })};
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (hover: hover) {
    ::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: ${t.color('g-text', 0.5)};
      }
    }
  }
`

export function QueryDrawer(props) {
  const location = ReactRouter.useLocation()
  const closeQuery = useSafeUpdateQuery({
    [props.activeQueryName]: null,
    ...props.additionalQueries,
  })

  const active = querystring.parse(location.search?.substring(1))[
    props.activeQueryName
  ]

  return (
    <AnimatePresence>
      {active && props.dismissable && (
        <ReactRouter.Link to={`?${closeQuery}`} key="overlay">
          <Overlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          />
        </ReactRouter.Link>
      )}

      {active && !props.dismissable && (
        <Overlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        />
      )}

      {active && (
        <Wrapper
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
          transition={{
            x: { type: 'spring', stiffness: 200, damping: 25 },
            default: { duration: 0.3 },
          }}
          key={props.activeQueryName}
          data-testid={`query-drawer-${props.activeQueryName}.container`}
        >
          <Common.Box
            mt={[4, null, '0px']}
            p={1}
            display="flex"
            justifyContent={props.dismissable ? 'space-between' : 'center'}
            alignItems="center"
            flexShrink="0"
          >
            {props.onSecondaryAction ? (
              <BackWrapper
                data-testid="query-drawer.back-button"
                onClick={props.onSecondaryAction}
              >
                {props.secondaryActionIcon ? (
                  <props.secondaryActionIcon
                    data-testid={`query-drawer.${props.title}-secondaryIcon`}
                  />
                ) : (
                  <Icons.KeyboardArrowLeft />
                )}
              </BackWrapper>
            ) : (
              <Common.Box width="30px" />
            )}
            <Common.Box
              fontSize={6}
              fontWeight="bold"
              color="white"
              display="inline-flex"
              overflow="hidden"
              fontFamily="head"
              data-testid="query-drawer.title"
            >
              {props.title}
            </Common.Box>
            {props.dismissable ? (
              <ReactRouter.Link
                to={`?${closeQuery}`}
                data-testid="query-drawer.close-button"
              >
                <Common.Text fontSize={6} color="lavender">
                  <Icons.Clear />
                </Common.Text>
              </ReactRouter.Link>
            ) : null}
          </Common.Box>
          <ScrollableWrapper>
            <ScrollLock />
            <Suspense.Boundary>{props.children}</Suspense.Boundary>
          </ScrollableWrapper>
        </Wrapper>
      )}
    </AnimatePresence>
  )
}

QueryDrawer.defaultProps = {
  additionalQueries: {},
  dismissable: true,
}

QueryDrawer.propTypes = {
  activeQueryName: PropTypes.string.isRequired,
  additionalQueries: PropTypes.object,
  children: PropTypes.node,
  dismissable: PropTypes.bool,
  secondaryActionIcon: PropTypes.func,
  title: PropTypes.string,
  onSecondaryAction: PropTypes.func,
}
