import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Processes from '@rushplay/processes'
import styled from '@emotion/styled'

import * as Constants from '../constants'
import * as Cookies from '../cookies-module'
import * as Theming from '../theming'
import { Button } from '../button'
import { Checkbox } from '../checkbox'
import { Divider } from '../divider'
import { InputField } from '../input-field'
import { InputPasswordField } from '../input-password-field'
import { LoadingNotificationWrapper } from '../loading-notification-wrapper'
import { SelectField } from '../select-field'
import { Spinner } from '../spinner'
import { useServerConfiguration } from '../server-configuration'

const preloadRegErrorKeys = [
  'errors.registration.address-base.unique-address',
  'errors.registration.address-international-phone.unique-mobile',
  'errors.registration.address-mobile.format',
  'errors.registration.address-zip.zip-restricted',
  'errors.registration.birthdate.',
  'errors.registration.birthdate.age-restriction',
  'errors.registration.email-uniqueness',
  'errors.registration.email.format',
  'errors.registration.ip-address.uniqueness',
  'errors.registration.lockero-external-id.already-exists',
  'errors.registration.phone-validation.phone-invalid',
  'errors.registration.phone-validation.phone-not-unique',
  'errors.registration.phone-verification-code.invalid',
  'errors.registration.phone-verification.too-many-requests',
]

const StyledLink = styled(ReactRouter.Link)`
  text-decoration: underline;
  color: #0e9fff;
`

function TermsLabel(props) {
  const translate = Herz.I18n.useTranslate(
    () => ['terms-and-conditions', 'privacy-policy', props.label],
    [props.label]
  )

  return (
    <Common.Box display="grid" gridGap={1}>
      <Common.Box style={{ whiteSpace: 'pre-wrap' }}>
        {translate(props.label)}
        <StyledLink to="/terms-and-conditions">
          {translate('terms-and-conditions')}
        </StyledLink>
      </Common.Box>
    </Common.Box>
  )
}

TermsLabel.propTypes = {
  label: PropTypes.string.isRequired,
}

function PrivacyLabel(props) {
  const translate = Herz.I18n.useTranslate(
    () => ['privacy-policy', props.label],
    [props.label]
  )

  return (
    <Common.Box display="grid" gridGap={1}>
      <Common.Box
        style={{ textDecoration: 'underline' }}
        fontSize={1}
        gridGap={0}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
      ></Common.Box>
      <Common.Box style={{ whiteSpace: 'pre-wrap' }}>
        {translate(props.label)}{' '}
        <StyledLink to="/privacy-policy">
          {translate('privacy-policy')}
        </StyledLink>
      </Common.Box>
    </Common.Box>
  )
}

PrivacyLabel.propTypes = {
  label: PropTypes.string.isRequired,
}

function AllowCheckbox(props) {
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
  })
  const translate = Herz.I18n.useTranslate(() => [field.label], [field.label])

  return (
    <Theming.Primary>
      <Checkbox
        id={field.name}
        name={field.name}
        checked={field.value}
        label={translate(field.label)}
        value={field.value}
        onChange={() => field.onChangeValue(!field.value)}
      />
    </Theming.Primary>
  )
}

AllowCheckbox.propTypes = {
  initialValue: PropTypes.bool,
  scope: PropTypes.string,
}

function TermsCheckBox(props) {
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
  })

  return (
    <Checkbox
      id={field.name}
      invalid={field.errors?.length > 0 && !props.suppressVisualFeedback}
      name={field.name}
      checked={field.value}
      label={<TermsLabel label={field.label} />}
      value={field.value}
      onChange={() => field.onChangeValue(!field.value)}
    />
  )
}

TermsCheckBox.propTypes = {
  initialValue: PropTypes.bool,
  scope: PropTypes.string,
  suppressVisualFeedback: PropTypes.bool,
}

function PrivacyCheckBox(props) {
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
  })

  return (
    <Checkbox
      id={field.name}
      invalid={field.errors?.length > 0 && !props.suppressVisualFeedback}
      name={field.name}
      checked={field.value}
      label={<PrivacyLabel label={field.label} />}
      value={field.value}
      onChange={() => field.onChangeValue(!field.value)}
    />
  )
}

PrivacyCheckBox.propTypes = {
  initialValue: PropTypes.bool,
  scope: PropTypes.string,
  suppressVisualFeedback: PropTypes.bool,
}

export function RegistrationForm(props) {
  const translate = Herz.I18n.useTranslate()
  const form = Forms.useFormContext()

  const { country } = useServerConfiguration()
  const [cookieData] = Cookies.useCookie(
    Constants.CookieKeys.REGISTRATION_FORM_DATA
  )

  const step1Loading = ReactRedux.useSelector(state =>
    Processes.isRunning(state.processes, {
      ids: [
        Constants.ProcessesIds.EMAIL_UNIQUENESS_REQUEST,
        Constants.ProcessesIds.PHONENUMBER_UNIQUENESS_REQUEST,
      ],
    })
  )

  const step2Loading = ReactRedux.useSelector(state =>
    Processes.isRunning(state.processes, {
      ids: [Constants.ProcessesIds.REGISTER_REQUEST],
    })
  )

  const callingCodeOptions = React.useMemo(
    () =>
      props.countryCallingCodes?.map(item => ({
        value: item,
        label: `+${item}`,
      })),
    [props.countryCallingCodes]
  )

  const countryOptions = React.useMemo(
    () =>
      props.countryNames?.map(item => ({
        value: item,
        label: `register.country.${item.toLowerCase().replace(' ', '-')}`,
      })),
    [props.countryNames]
  )

  return (
    <React.Fragment>
      {props.step === Constants.RegistrationStep.Credentials && (
        <Common.Box display="grid" gridGap={1}>
          <InputField
            autoComplete="email"
            customErrorKey={props.emailErrorKey}
            inputMode="email"
            initialValue={cookieData?.email ?? ''}
            scope="#/properties/email"
            suppressVisualFeedback={!props.showVisualFeedback}
            onClearCustomErrorKey={props.onClearEmailErrorKey}
          />
          <InputPasswordField
            autoComplete="new-password"
            initialValue={cookieData?.password ?? ''}
            scope="#/properties/password"
            suppressVisualFeedback={!props.showVisualFeedback}
          />

          <Common.Box
            display="grid"
            gridTemplateColumns="80px 1fr"
            gridGap={0}
            alignItems="start"
          >
            <SelectField
              contentTranslated
              disabled={callingCodeOptions?.length === 1}
              autoComplete="tel-country-code"
              initialValue={country?.countryCode.toString() ?? ''}
              options={callingCodeOptions}
              scope="#/properties/countryCallingCode"
              suppressVisualFeedback={!props.showVisualFeedback}
            />
            <InputField
              autoComplete="tel"
              customErrorKey={props.phoneErrorKey}
              inputMode="tel"
              maxLength="11"
              scope="#/properties/phonenumber"
              suppressVisualFeedback={!props.showVisualFeedback}
              onClearCustomErrorKey={props.onClearPhoneErrorKey}
            />
          </Common.Box>
          <LoadingNotificationWrapper
            shouldDisplay={step1Loading}
            name={form.name}
          >
            <Common.Box pt={2} mx="auto" width="160px">
              <Button
                stretch
                fontSize={[2, 3]}
                loading={step1Loading}
                type="submit"
                variant="primary"
                width="160px"
                disabled={step1Loading}
              >
                {step1Loading ? (
                  <Common.Box
                    maxWidth="30px"
                    maxHeight="30px"
                    m="-13px auto 7px"
                  >
                    <Spinner />
                  </Common.Box>
                ) : (
                  translate('register.step1.cta')
                )}
              </Button>
            </Common.Box>
          </LoadingNotificationWrapper>
          <Common.Box pt={0} pb={1} textAlign="center" lineHeight="1.6">
            <Common.Text color="darken-gray">
              {translate('registration.switch-method.content')}
            </Common.Text>
            <Common.Text textDecoration="underline">
              <Common.Link to="?login=me" data-testid="register-page-login">
                {translate('registration.switch-method.cta')}
              </Common.Link>
            </Common.Text>
          </Common.Box>
        </Common.Box>
      )}

      {props.step === Constants.RegistrationStep.Identity && (
        <Common.Box display="grid" gridGap={1}>
          <Common.Box color="base-gray">
            <Common.Box
              textTransform="uppercase"
              fontWeight="bold"
              color="base-gray"
              fontSize="18px"
              lineHeight="24px"
              mb="8px"
            >
              {translate('registration.personal-info')}
            </Common.Box>
            <Divider />
          </Common.Box>
          <Common.Box
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gridGap={0}
            alignItems="start"
          >
            <InputField
              autoComplete="given-name"
              scope="#/properties/firstName"
              suppressVisualFeedback={!props.showVisualFeedback}
            />
            <InputField
              autocomplete="family-name"
              scope="#/properties/lastName"
              suppressVisualFeedback={!props.showVisualFeedback}
            />
          </Common.Box>
          <Common.Box>
            <Common.Box
              fontSize={1}
              fontFamily="body"
              py="0.35em"
              color="base-gray"
            >
              {translate('registration.birthdate')}
            </Common.Box>
            <Common.Box
              display="grid"
              gridTemplateColumns="repeat(3, 1fr)"
              gridGap={0}
              pb={0}
              alignItems="start"
            >
              <InputField
                autoComplete="bday-day"
                inputMode="numeric"
                maxLength="2"
                scope="#/properties/bdayDay"
                suppressVisualFeedback={!props.showVisualFeedback}
                hideLabel
              />
              <InputField
                autoComplete="bday-month"
                inputMode="numeric"
                maxLength="2"
                scope="#/properties/bdayMonth"
                suppressVisualFeedback={!props.showVisualFeedback}
                hideLabel
              />
              <InputField
                autoComplete="bday-year"
                inputMode="numeric"
                maxLength="4"
                scope="#/properties/bdayYear"
                suppressVisualFeedback={!props.showVisualFeedback}
                hideLabel
              />
            </Common.Box>
          </Common.Box>

          <Common.Box color="base-gray">
            <Common.Box
              textTransform="uppercase"
              fontWeight="bold"
              fontSize="18px"
              lineHeight="24px"
              mb="8px"
            >
              {translate('registration.location')}
            </Common.Box>
            <Divider />
          </Common.Box>

          {countryOptions?.length > 1 ? (
            <SelectField
              autoComplete="country-name"
              initialValue={country.name}
              options={countryOptions}
              scope="#/properties/country"
              suppressVisualFeedback={!props.showVisualFeedback}
            />
          ) : null}

          <InputField
            autoComplete="street-address"
            scope="#/properties/street"
            suppressVisualFeedback={!props.showVisualFeedback}
          />

          <Common.Box
            display="grid"
            gridTemplateColumns="110px 1fr"
            gridGap={0}
            alignItems="start"
          >
            <InputField
              scope="#/properties/zip"
              suppressVisualFeedback={!props.showVisualFeedback}
            />
            <InputField
              autoComplete="address-level2"
              scope="#/properties/city"
              suppressVisualFeedback={!props.showVisualFeedback}
            />
          </Common.Box>

          <TermsCheckBox
            initialValue
            scope="#/properties/terms"
            suppressVisualFeedback={!props.showVisualFeedback}
          />
          <PrivacyCheckBox
            initialValue
            scope="#/properties/privacy"
            suppressVisualFeedback={!props.showVisualFeedback}
          />
          <AllowCheckbox
            initialValue
            scope="#/properties/promotional"
            suppressVisualFeedback={!props.showVisualFeedback}
          />
          <LoadingNotificationWrapper
            shouldDisplay={step2Loading}
            name={form.name}
          >
            <Common.Box pt={2} mx="auto" width="160px">
              <Button
                stretch
                fontSize={[2, 3]}
                loading={step2Loading}
                type="submit"
                variant="primary"
                disabled={step2Loading}
              >
                {step2Loading ? (
                  <Common.Box
                    maxWidth="30px"
                    maxHeight="30px"
                    m="-13px auto 7px"
                  >
                    <Spinner />
                  </Common.Box>
                ) : (
                  translate('register.step2.cta')
                )}
              </Button>
            </Common.Box>
          </LoadingNotificationWrapper>
        </Common.Box>
      )}
    </React.Fragment>
  )
}

RegistrationForm.propTypes = {
  countryNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  countryCallingCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  emailErrorKey: PropTypes.string,
  phoneErrorKey: PropTypes.string,
  showVisualFeedback: PropTypes.bool,
  step: PropTypes.number.isRequired,
  onClearEmailErrorKey: PropTypes.func,
  onClearPhoneErrorKey: PropTypes.func,
}

Herz.I18n.Loader.preload(
  [
    'errors.unsupported-country',
    'register.step1.cta',
    'register.step2.cta',
    'registration.switch-method.cta',
    'registration.switch-method.content',
    'registration.personal-info',
    'registration.location',
    'registration.birthdate',
    ...preloadRegErrorKeys,
  ],
  RegistrationForm
)
