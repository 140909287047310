import * as R from 'ramda'
import * as React from 'react'
import LazyLoad from 'react-lazyload'
import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'
import * as Emotion from '@emotion/core'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import { DynamicImage } from './dynamic-image'
import {
  GAME_TILE_MULTIPLIER,
  GameTileHeight,
  GameTileProviderLogosWidths,
  GameTileProviderLogosWidthsDefault,
  GameTileWidth,
} from './constants'
import { GameTileLoading } from './game-tile-loading'
import { useImgproxyUrl } from './use-imgproxy-url'

function toCssArray(items) {
  return items.join(',')
}

function imageWidth(size, multiplier = 1) {
  switch (size) {
    case 'large': {
      return Math.floor(GameTileWidth.LARGE * multiplier)
    }
    case 'medium': {
      return Math.floor(GameTileWidth.MEDIUM * multiplier)
    }
    case 'small': {
      return Math.floor(GameTileWidth.SMALL * multiplier)
    }
    default: {
      return Math.floor(GameTileWidth.SMALL * multiplier)
    }
  }
}

function tileHeight(props, multipler = 1) {
  switch (props.size) {
    case 'large': {
      return `${GameTileHeight.LARGE * multipler}px`
    }
    case 'medium': {
      return `${GameTileHeight.MEDIUM * multipler}px`
    }
    // Game tiles on bitdreams.com My Games page have a specific height
    // TODO: make it more customizable (per variant)
    // See https://app.clickup.com/t/2574244/PLF-428
    case 'picked-games': {
      return `${GameTileHeight.PICKED_GAMES * multipler}px`
    }
    default: {
      return `${GameTileHeight.SMALL * multipler}px`
    }
  }
}

export function titleSizeSmall(size) {
  switch (size) {
    case 'large': {
      return 3
    }
    case 'medium': {
      return 1
    }
    default: {
      return 1
    }
  }
}

export function titleSizeMedium(size) {
  switch (size) {
    case 'large': {
      return 5
    }
    case 'medium': {
      return 3
    }
    default: {
      return 1
    }
  }
}

function url(url) {
  if (url == null) {
    return 'none'
  }

  return `url(${url})`
}

const StyledBackground = styled('div', {
  shouldForwardProp: Common.noneOf([
    'alignBottom',
    'backgroundSrc',
    'disabled',
    'overlaySrc',
    'overlayBackgroundPosition',
    'size',
  ]),
})`
  position: relative;
  user-select: none;
  ${props => {
    if (props.backgroundSrc == null) {
      return Emotion.css`
        background-image: url(/images/default-tile-background.svg);
        background-size: 40px;
      `
    }

    return Emotion.css`
      background-image: ${toCssArray([
        url(props.overlaySrc),
        url(props.backgroundSrc),
      ])};
      background-origin: ${toCssArray(['content-box', 'border-box'])};
      background-position: ${toCssArray(
        props.alignBottom ? ['bottom center', 'center'] : ['center', 'center']
      )};
      background-repeat: ${toCssArray(['no-repeat', 'no-repeat'])};
      background-size: ${toCssArray(['contain', 'cover'])};
    `
  }};
  ${props => {
    // Override "small" with "medium" for smaller screens
    const size = props.size === 'small' ? 'medium' : props.size

    return Emotion.css`
      height: ${tileHeight({ size }, GAME_TILE_MULTIPLIER)};

      /* Writing media query by hand as we can’t run functions inside "css" */
      @media (min-width: ${props.theme.breakpoints.sm}) {
        height: ${tileHeight(props)};
      }
    `
  }};
  ${props => props.disabled && 'opacity: 0.5'};
  ${props =>
    css(
      R.mergeAll([
        {
          backgroundColor: 'game-tile-background',
          borderRadius: 1,
          position: 'relative',
        },
        props.overlayBackgroundPosition
          ? { paddingTop: [0, 1] }
          : { paddingY: [0, 1], paddingX: [0, 1] },
      ])
    )}
`

export function Title(props) {
  return (
    <Common.Box
      fontSize={[titleSizeSmall(props.size), titleSizeMedium(props.size)]}
      textTransform="capitalize"
      mt="auto"
      mb={1}
      textAlign="center"
      color="static-white"
      data-testid="game-tile.title"
    >
      {props.children}
    </Common.Box>
  )
}

Title.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'picked-games']),
  children: PropTypes.string,
}

export function TitleImage(props) {
  return (
    <DynamicImage
      alt={props.alt}
      resizingWidth={
        props.size === 'small'
          ? imageWidth(props.size, 1.3)
          : imageWidth(props.size, 0.8)
      }
      resizingType="fit"
      source={props.titleUrl}
      maxHeight="30%"
      maxWidth="95%"
      pb={[0, 1]}
      mt="auto"
      quality={props.size === 'small' ? 50 : 85}
    />
  )
}

TitleImage.propTypes = {
  alt: PropTypes.string,
  titleUrl: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'picked-games']),
}

const Text = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  textshadow: 1px 1px 3px black;

  ${css({
    color: 'static-white',
    fontSize: 2,
  })}
`

export function ProviderLogo(props) {
  const id = Herz.Utils.Strings.toKebabCase(props.provider)

  if (!props.providerLogo) {
    return <Text data-testid="game-tile.text-logo">{props.provider}</Text>
  }

  return (
    <React.Fragment>
      {props.providerLogo.startsWith('https') ? (
        <Common.Box
          maxWidth="80%"
          width={
            GameTileProviderLogosWidths[id] ||
            GameTileProviderLogosWidthsDefault
          }
          zIndex="1"
          data-testid="game-tile.image-logo"
        >
          <img alt={props.provider} src={props.providerLogo} />
        </Common.Box>
      ) : (
        <Text data-testid="game-tile.text-logo">{props.providerLogo}</Text>
      )}
    </React.Fragment>
  )
}

ProviderLogo.propTypes = {
  provider: PropTypes.string,
  providerLogo: PropTypes.string,
}

/**
 * The background is a composite of game background and overlay image.
 */
export function Background(props) {
  const [backgroundSrc] = useImgproxyUrl(props.backgroundUrl, {
    extension: 'jpg',
    resizingWidth: imageWidth(props.size, 2),
  })

  const [overlaySrc] = useImgproxyUrl(props.backgroundOverlayUrl, {
    resizingType: 'fit',
    resizingWidth: imageWidth(props.size),
  })

  const overlayBackgroundPosition = R.equals(
    props.backgroundOverlayImageAlignment,
    'bottom'
  )

  return (
    <StyledBackground
      backgroundSrc={backgroundSrc}
      disabled={props.disabled}
      overlaySrc={props.backgroundUrl && overlaySrc}
      overlayBackgroundPosition={overlayBackgroundPosition}
      size={props.size}
    >
      {props.children}
    </StyledBackground>
  )
}

Background.propTypes = {
  backgroundUrl: PropTypes.string,
  backgroundOverlayImageAlignment: PropTypes.string,
  backgroundOverlayUrl: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'picked-games']),
}

export function Loader(props) {
  const overrideSize = props.size === 'small' ? 'medium' : props.size

  return (
    <LazyLoad
      once
      resize
      offset={200}
      placeholder={
        <GameTileLoading
          title={props.title || 'Loading'}
          height={[
            tileHeight({ size: overrideSize }, GAME_TILE_MULTIPLIER),
            tileHeight({ size: overrideSize }),
          ]}
        />
      }
    >
      {props.children}
    </LazyLoad>
  )
}

Loader.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'picked-games']),
  title: PropTypes.string,
}

export function Jackpot(props) {
  return (
    <Common.Box
      fontSize={[0, 3]}
      background="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)"
      textAlign="center"
      justifyContent="center"
      position="absolute"
      display="flex"
      left="0px"
      right="0px"
      bottom="0px"
      padding="8px"
    >
      {props.children}
    </Common.Box>
  )
}

Jackpot.propTypes = {
  children: PropTypes.node,
}
