import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReduxEffects from 'redux-effects'
import * as ReactRouter from 'react-router-dom'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import { lazy as loadable } from '@loadable/component'

import * as Constants from '../constants'
import * as Cookies from '../cookies'
import * as Player from '../player'
import * as Suspense from '../suspense'
import * as Configuration from '../configuration'
import { Footer } from '../footer'
import { Home } from '../home'
import { HorseRacing } from '../horse-racing'
import { LocationPicker } from '../locale/change-location'
import { PrivateRoute } from '../private-route'
import { PromotionsDrawer } from '../promotions-drawer'
import { RegistrationDrawer } from '../registration-drawer/registration-drawer'
import { Wallet } from '../wallet'
import { useScrollToTop } from '../use-scroll-to-top'

const Account = loadable(() => import('../account'))
const Casino = loadable(() => import('../casino'))
const DevTools = loadable(() => import('../dev-tools'))
const Faq = loadable(() => import('../faq'))
const GamePage = loadable(() => import('../game-page'))
const LiveCasino = loadable(() => import('../live-casino'))
const Mahjong = loadable(() => import('../mahjong'))
const Logout = loadable(() => import('../logout'))
const LoginDrawer = loadable(() => import('../login-drawer'))
const MainMenu = loadable(() => import('../main-menu'))
const Moments = loadable(() => import('../moments'))
const NotFound = loadable(() => import('../not-found'))
const PasswordRecovery = loadable(() => import('../password-recovery'))
const PasswordReset = loadable(() => import('../password-reset'))
const PromotionTermsAndConditionsPage = loadable(() =>
  import('../promotion-terms-and-conditions-page')
)
const StaticPage = loadable(() => import('../static-page'))
const TermsAndConditions = loadable(() => import('../terms-and-conditions'))
const VipPage = loadable(() => import('../vip-page'))

export function Primary() {
  const [ref, handleScrollToTop] = useScrollToTop()
  const dispatch = ReactRedux.useDispatch()
  const location = ReactRouter.useLocation()
  const { authenticated } = Herz.Auth.useSession()
  const isMahjongEnabled = ReactRedux.useSelector(state =>
    Configuration.isMahjongEnabled(state.configuration)
  )
  const isHorseRacingEnabled = ReactRedux.useSelector(state =>
    Configuration.isHorseRacingEnabled(state.configuration)
  )
  const env = ReactRedux.useSelector(state =>
    Configuration.getEnvironment(state.configuration)
  )

  React.useEffect(() => {
    if (!authenticated) {
      dispatch(
        ReduxEffects.bind(
          Cookies.get(Constants.CookieKeys.HORSE_RACING_SEEN),
          value => value && Player.updateHorseRacingSeen(JSON.parse(value))
        )
      )
    }
  }, [authenticated, dispatch])

  return (
    <React.Fragment>
      <ReactRouter.Route path="/" component={MainMenu} />
      <Common.Box ref={ref}>
        <Common.Box
          minHeight={[
            'calc(var(--window-inner-height, 100vh) - 46px)',
            null,
            'var(--window-inner-height, 100vh)',
          ]}
          display="flex"
        >
          <Suspense.Boundary>
            <ReactRouter.Switch>
              <ReactRouter.Route exact path="/">
                <Home onScrollToTop={handleScrollToTop} />
              </ReactRouter.Route>

              <ReactRouter.Redirect
                from="/locks"
                to={{ pathname: '/', search: location.search }}
              />

              {env === 'staging' && (
                <ReactRouter.Route
                  path="/change-location"
                  component={LocationPicker}
                />
              )}

              <ReactRouter.Route
                path="/casino/games/:gameId"
                component={GamePage}
              />

              <ReactRouter.Route path="/casino" component={Casino} />

              {isHorseRacingEnabled ? (
                <ReactRouter.Route
                  path="/horse-racing"
                  component={HorseRacing}
                />
              ) : null}

              <ReactRouter.Route path="/moments" component={Moments} />

              {isMahjongEnabled && (
                <ReactRouter.Route path="/mahjong" component={Mahjong} />
              )}

              <PrivateRoute path="/account" component={Account} />

              <ReactRouter.Route path="/live-casino" component={LiveCasino} />

              <ReactRouter.Route
                exact
                path="/password-recovery"
                component={PasswordRecovery}
              />

              <ReactRouter.Redirect
                exact
                from="/password/:token"
                to="/password-reset/:token"
              />
              <ReactRouter.Route
                path="/password-reset/:token"
                component={PasswordReset}
              />

              <ReactRouter.Route
                path={[
                  '/(about)',
                  '/(privacy-policy)',
                  '/(responsible-gaming)',
                  '/(dispute-resolution)',
                  '/(anti-money-laundering)',
                  '/(fairness-rng-testing-methods)',
                  '/(cookie-policy)',
                  '/(kyc-policies)',
                  '/(self-exclusion)',
                  '/(security)',
                  '/(accounts-payouts-bonuses)',
                ]}
                component={StaticPage}
              />

              <ReactRouter.Route
                path="/promotions/:key"
                component={PromotionTermsAndConditionsPage}
              />

              <ReactRouter.Route path="/faq" component={Faq} />

              <ReactRouter.Route
                path="/terms-and-conditions"
                component={TermsAndConditions}
              />

              <ReactRouter.Route path="/vip" component={VipPage} />

              <ReactRouter.Route path="/logout" component={Logout} />

              {/* Keep existing functionality for people who still have it for a while */}
              <ReactRouter.Redirect
                from="/login"
                to={{ pathname: '/', search: '?login=me' }}
              />

              <ReactRouter.Route path="/dev-tools">
                <DevTools />
              </ReactRouter.Route>

              <ReactRouter.Route
                path="/500"
                render={() => {
                  throw new Error('Failure was successful')
                }}
              />

              <ReactRouter.Route component={NotFound} />
            </ReactRouter.Switch>
          </Suspense.Boundary>
        </Common.Box>
        <Footer />
      </Common.Box>
      <PromotionsDrawer />
      <Wallet />
      <LoginDrawer />
      <RegistrationDrawer />
    </React.Fragment>
  )
}

// For @loadable/component
export default Primary
