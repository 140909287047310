import * as React from 'react'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import { lazy as loadable } from '@loadable/component'

import * as Constants from '../constants'
import { CurrencyProviderImages } from '../currency-provider-images'
import { GameProviderImages } from '../game-provider-images'
import { LiveCasinoGameDisplay } from '../live-casino-game-display'
import { SellingPoint } from '../selling-points'
import { SeoSection } from '../seo-section'

import { LandingBanner } from './landing-banner'

const LandingPageCategory = loadable(() => import('../landing-page-category'))

export function LandingPage() {
  const translate = Herz.I18n.useTranslate()
  return (
    <Common.Box width="100%" display="flex" justifyContent="center">
      <Common.Box
        maxWidth={Constants.ContentMaxWidth}
        width="100%"
        p={['0px', 40]}
      >
        <LandingBanner />
        <Common.Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mx="auto"
          maxWidth={Constants.ContentMaxWidth}
        >
          <Common.Box py={[1, 3]} px="0px">
            <CurrencyProviderImages />
          </Common.Box>
          <Common.Box maxWidth="1080px" width="100%" mx="auto">
            <LandingPageCategory />
            <LiveCasinoGameDisplay />
          </Common.Box>
          <Common.Box my="2" width="100%" backgroundColor="#1A0B41">
            <GameProviderImages />
          </Common.Box>
          <Common.Box
            py={1}
            width="100%"
            display="grid"
            gridTemplateColumns={[
              '100%',
              '1fr 1fr',
              null,
              'repeat(auto-fill, minmax(260px, 1fr))',
            ]}
            gridGap="15px"
            justifyItems="center"
            alignItems="center"
            margin="auto"
          >
            <SellingPoint
              title={translate('landing-page.usp.title.1')}
              description={translate('landing-page.usp.description.1')}
              img={translate('landing-page.usp.image.1')}
            />
            <SellingPoint
              title={translate('landing-page.usp.title.2')}
              description={translate('landing-page.usp.description.2')}
              img={translate('landing-page.usp.image.2')}
            />
            <SellingPoint
              title={translate('landing-page.usp.title.3')}
              description={translate('landing-page.usp.description.3')}
              img={translate('landing-page.usp.image.3')}
            />
            <SellingPoint
              title={translate('landing-page.usp.title.4')}
              description={translate('landing-page.usp.description.4')}
              img={translate('landing-page.usp.image.4')}
            />
          </Common.Box>
          <Common.Box my={10} width="100%">
            <SeoSection />
          </Common.Box>
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    'landing-page.usp.title.1',
    'landing-page.usp.title.2',
    'landing-page.usp.title.3',
    'landing-page.usp.title.4',
    'landing-page.usp.image.1',
    'landing-page.usp.image.2',
    'landing-page.usp.image.3',
    'landing-page.usp.image.4',
    'landing-page.usp.description.1',
    'landing-page.usp.description.2',
    'landing-page.usp.description.3',
    'landing-page.usp.description.4',
  ],
  LandingPage
)

export default LandingPage
