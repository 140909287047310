import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'

import * as Configuration from './configuration'

const Image = styled.img`
  width: auto;
  height: auto;
`
const Slider = styled(Common.Box)`
  @media (max-width: 500px) {
    overflow: hidden;
    white-space: nowrap;
    position: relative;

    &:before {
      position: absolute;
      top: 0;
      width: 70px;
      height: 100%;
      content: "";
      z-index: 2;
      left: 0;
      background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(19, 8, 48, 1));
    }

    &:after {
      position: absolute;
      top: 0;
      width: 70px;
      height: 100%;
      content: "";
      z-index: 2;
      right: 0;
      background: linear-gradient(to right, rgba(0, 255, 255, 0), rgba(19, 8, 48, 1));
    );
    }
  }
`

const ProviderImagesContainer = styled(Common.Box)`
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  min-width: 500px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: rgba(140, 172, 255, 1);
  gap: 8px 30px;

  @media (max-width: 500px) {
    @keyframes slide {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-100%);
      }
    }

    gap: 40px;
    flex-wrap: nowrap;
    display: inline-flex;
    border: none;
    padding: 16px 20px;
    justify-content: space-around;
    animation: 20s slide infinite linear;
  }
`

export function CurrencyProviderImages() {
  const paymentProviders = ReactRedux.useSelector(state =>
    Configuration.getPaymentProviderImageUrls(state.configuration)
  )

  return (
    <Slider>
      <ProviderImagesContainer display="flex">
        {paymentProviders?.map(item => (
          <Common.Box
            key={`1-${item?.image?.url}`}
            maxWidth="170px"
            alignItems="center"
            display="flex"
            justifyContent="center"
            my={0}
          >
            <Image src={item?.image?.url} alt={item.name} />
          </Common.Box>
        ))}
      </ProviderImagesContainer>
      <ProviderImagesContainer display="none">
        {paymentProviders?.map(item => (
          <Common.Box
            key={`2-${item?.image?.url}`}
            maxWidth="170px"
            alignItems="center"
            display="flex"
            justifyContent="center"
            my={0}
          >
            <Image src={item?.image?.url} alt={item.name} />
          </Common.Box>
        ))}
      </ProviderImagesContainer>
    </Slider>
  )
}
