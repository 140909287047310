import { border } from 'styled-system'

import styled from '@emotion/styled'

export const Divider = styled.hr`
  opacity: 0.3;
  border: none;
  border-top: 1px solid currentColor;
  ${border};
`
